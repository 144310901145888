import e,{createContext as u,Suspense as k,lazy as a}from"../_snowpack/pkg/react.js";import{useMachine as h}from"../_snowpack/pkg/@xstate/react.js";import{animated as s,useSpring as x,useTransition as w,config as y}from"../_snowpack/pkg/react-spring.js";import o from"../_snowpack/pkg/styled-components.js";import{pokemonMachine as E}from"./machine/uiMachine.js";import v from"./machine/initialContext.js";import{POKEMON_COLORS as m,removeHyphens as z}from"./utils/index.js";const P=a(()=>import("./components/PokemonSprite.js")),b=a(()=>import("./components/PokemonStats.js"));export const MachineProvider=u({pokemonInfo:{...v}});function c({}){return e.createElement(l,null,"Loading...")}function j({}){const[n]=h(E),t=n.context.pokemonInfo,p=x({backgroundColor:`${n.matches("present")?m.get(t.color.toLowerCase()).hsl:"white"}`,color:`${n.matches("present")?m.get(t.color.toLowerCase()).fontColor:"white"}`}),d=w(n.matches("present"),{from:{opacity:0,transform:"translate3d(0px, 30px, 0px)"},enter:{opacity:1,transform:"translate3d(0px, 0px, 0px)"},leave:{opacity:0},delay:500,config:y.molasses});return n.matches("present")?e.createElement(MachineProvider.Provider,{value:n.context},e.createElement(k,{fallback:e.createElement(c,null)},e.createElement(l,{style:p},d((g,f)=>f&&e.createElement(C,{style:g},e.createElement(P,{id:t.pokemonId,name:t.name,spriteUrl:t.spriteUrl}),e.createElement(M,null,e.createElement(S,null,"#",t.pokemonId," ",z(t.name)),e.createElement(I,null,t.types.map((i,r)=>e.createElement("span",{key:`pokemon-ability-key-${r}`},t.types.length-1===r?i:`${i}, `)))),e.createElement(L,null,e.createElement(b,{abilities:t.abilities,baseExperience:t.baseExperience,height:t.height,weight:t.weight}))))))):e.createElement(c,null)}const l=o(s.main)`
	display: flex;
	align-items: center;
	justify-content: center;
	min-block-size: 100vh;
	min-inline-size: 100vw;
	background-color: var(--bg-loading);
	font-size: min(var(--main-font-size), 4vw);
`,C=o(s.section)`
	display: grid;
	align-items: start;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, min-content);
	padding-inline: 10px;

	@media screen and (orientation: landscape) {
		font-size: min(var(--main-font-size), 4vh);
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: min-content max-content;
		align-items: start;
		// inline-size: 100%;
		// grid-template-rows: 1fr;
	}
`,S=o.h1`
	font-size: min(2em, 7vw);
	text-transform: capitalize;
`,I=o.h2`
	font-size: min(1em, 4vw);
`,M=o.div`
	grid-column: 1 / -1;
	grid-row: 1 / span 1;
	text-align: center;

	@media screen and (orientation: landscape) {
		align-self: end;
		grid-column: 2 / -1;
		grid-row: 1 / span 1;
	}
`,L=o.div`
	grid-column: 1 / -1;
	grid-row: 3 / -1;

	@media screen and (orientation: landscape) {
		grid-column: 2 / -1;
		grid-row: 2 / -1;
	}
`;export default j;
